<template>
  <!-- Feature Section -->
  <div class="mb-3" v-bind:id="'feature-accordion-' + componentId">
    <div class="card">
      <!-- Accordion Header -->
      <div class="card-header p-0" v-bind:id="'feature-head-' + componentId">
        <h2 class="mb-0">
          <button
            class="btn btn-white btn-block text-left p-3"
            type="button"
            data-toggle="collapse"
            v-bind:data-target="'#feature-collapse-' + componentId"
            aria-expanded="true"
            v-bind:aria-controls="'feature-collapse-' + componentId"
          >
            <h5 class="custom-card-title">
              <!-- Icon -->
              <i v-if="iconClass" v-bind:class="iconClass"></i>
              <!-- ./Icon -->

              <!-- Title -->
              <span>{{ title }}</span>
              <!-- ./Title -->

              <i
                class="fas fa-chevron-down float-right font-14 mt-1 text-muted"
              ></i>
            </h5>
          </button>
        </h2>
      </div>
      <!-- Accordion Header -->

      <!-- Feature Accordion Body -->
      <div
        v-bind:id="'feature-collapse-' + componentId"
        class="collapse"
        v-bind:class="{ show: !collapsed }"
        v-bind:aria-labelledby="'feature-head-' + componentId"
        v-bind:data-parent="'#feature-accordion-' + componentId"
      >
        <div class="card-body">
          <div class="position-relative">
            <!-- Search Feature -->
            <input
              v-if="checkFeatureLimit"
              type="text"
              class="form-control"
              v-bind:placeholder="searchPlaceholder"
              autocomplete="off"
              spellcheck="false"
              v-model="searchTerm"
              v-on:input="onInput"
              v-on:focus="featureVisible = true"
              v-on:blur="featureBlur"
            />
            <!-- ./Search Feature -->

            <div class="card alert-info" v-else>
              <div class="card-body">
                <ul class="mb-0">
                  <li>En fazla {{ featureLimit }} adet Özellik eklenebilir.</li>
                  <li>
                    En fazla {{ valueLimit }} adet Özellik Değeri seçilebilir.
                  </li>
                </ul>
              </div>
            </div>
            <!-- Result Count -->
            <div
              class="result-count"
              v-if="searchTerm && searchTerm.length >= searchLength"
            >
              <div v-if="!searchReady">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
              <div v-else>
                <div
                  v-if="
                    searchTerm.length &&
                    filteredFeatureList &&
                    filteredFeatureList.length
                  "
                >
                  <span class="badge badge-pill alert-primary"
                    >{{ filteredFeatureList.length }} Sonuç</span
                  >
                </div>
                <div v-else>
                  <span class="badge badge-pill alert-dark"
                    >Sonuç Bulunamadı</span
                  >
                </div>
              </div>
            </div>
            <!-- ./Result Count -->

            <!-- Search Result Card -->
            <div
              class="dropdown-menu shadow-sm col-12 custom-scrollbar"
              style="max-height: 300px; overflow: auto"
              v-bind:class="{
                show:
                  filteredFeatureList &&
                  featureVisible &&
                  filteredFeatureList.length,
              }"
            >
              <span
                class="btn dropdown-item py-2"
                v-for="feature in filteredFeatureList"
                v-bind:key="feature.name"
                v-on:click="getFeature(feature)"
              >
                {{ feature.name }}
              </span>
            </div>
            <!-- ./Search Result Card -->
          </div>

          <!-- Feature Values -->
          <div
            class="custom-scrollbar"
            v-if="featureValue && computedData.length"
          >
            <!-- Feature List -->
            <div
              class="feature-group-card"
              v-for="(feature, i) in computedData"
              v-bind:key="feature.feature_id + '-' + i"
            >
              <!-- Feature Name -->
              <label
                v-bind:for="feature.feature_id + '-' + i"
                class="feature-title"
                >{{ feature.feature_name }}</label
              >
              <!-- ./Feature Name -->

              <!-- Feature Remove -->
              <span
                class="btn feature-remove"
                v-on:click="removeFeature(feature.feature_id)"
              >
                <i class="fas fa-trash"></i> Sil
              </span>
              <!-- ./Feature Remove -->

              <!-- Feature Selections -->
              <div class="card-body" v-if="!multipleValue">
                <select
                  v-if="valueType == 'select'"
                  v-bind:name="feature.name"
                  v-bind:id="feature.feature_id + '-' + i"
                  class="custom-select border-0"
                >
                  <option
                    v-for="option in feature.values"
                    v-bind:key="option.id"
                    v-bind:value="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
                <div v-else class="position-relative">
                  <input
                    type="text"
                    v-bind:id="feature.feature_id + '-' + i"
                    v-model="feature.value"
                    v-on:input="onFeatureValueChange(feature, i, $event)"
                    v-on:focus="filteredValueVisible = true"
                    v-on:blur="onBlur"
                    autocomplete="off"
                    class="form-control"
                  />
                  <!-- Search Value Result Card -->
                  <div
                    class="dropdown-menu shadow-sm col-12 custom-scrollbar"
                    style="max-height: 300px; overflow: auto"
                    v-bind:class="{
                      show:
                        valueSearchIndex == i &&
                        filteredValueVisible &&
                        filteredValueSearch.length,
                    }"
                  >
                    <span
                      class="btn dropdown-item py-2"
                      v-for="value in filteredValueSearch"
                      v-bind:key="value.id"
                      v-on:click="addFeatureValue(feature, value)"
                    >
                      {{ value.name }}
                    </span>
                  </div>
                  <!-- ./Search Value Result Card -->
                </div>
              </div>
              <div class="card-body" v-else>
                <v-select
                  label="name"
                  v-bind:options="feature.values"
                  v-model="feature.value"
                  multiple
                  clearable
                  v-on:input="$emit('value-update', computedData)"
                  v-bind:close-on-select="false"
                  v-bind:selectable="() => getSelectable(feature)"
                ></v-select>
              </div>
              <!-- ./Feature Selections -->
            </div>
            <!-- ./Feature List -->
          </div>
          <!-- ./Feature Values -->

          <!-- Feature Only Values -->
          <div class="custom-scroll" v-else>
            <div
              class="feature-group-card mt-2"
              v-for="feature in computedData"
              v-bind:key="feature.feature_id"
            >
              <div class="card">
                <div class="card-body py-2 pr-2">
                  <div class="row align-items-center">
                    <div class="col">
                      {{ feature.feature_name }}
                    </div>
                    <div class="col-auto">
                      <span
                        class="btn btn-outline-danger btn-sm"
                        v-on:click="removeFeature(feature.feature_id)"
                        ><i class="fas fa-trash"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ./Feature Only Values -->
        </div>
        <!-- ./Feature Accordion Body -->
      </div>
    </div>
  </div>
  <!-- ./Feature Section -->
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "WeFeature",
  data() {
    return {
      searchTerm: "",
      searchReady: false,
      searchResult: 0,
      featureVisible: false,
      filteredFeatureList: [],

      valueSearchIndex: -1,
      filteredValueSearch: [],
      filteredValueVisible: false,

      componentId: "wefeature-" + helper.getRandomInteger(1, 999999),
    };
  },
  props: {
    data: {
      features: {
        type: Array,
        default: () => [],
      },
    },
    featureValue: {
      default: false,
    },
    valueType: {
      default: "input",
    },
    searchDelay: {
      default: 1000,
    },
    searchLength: {
      default: 1,
    },
    searchPlaceholder: {
      default: "Özellik Ara",
    },
    title: {
      default: "ÖZELLİKLER",
    },
    iconClass: {
      default: "fas fa-network-wired",
    },
    getRequest: {
      default: false,
    },
    multipleValue: {
      default: false,
    },
    featureLimit: {
      default: 0,
    },
    valueLimit: {
      default: 0,
    },
    collapsed: {
      default: true,
    },
    language: {
      default: "tr",
    },
  },
  methods: {
    ...mapActions("shared", [
      "load",
      "searchFeature",
      "searchFeatureValue",
      "getFeatureById",
    ]),
    clearFeatureList() {
      this.searchReady = false;
      this.filteredFeatureList = [];
    },
    onInput(e) {
      this.doSearch(this.searchTerm, this.searchDelay);
    },
    doSearch(text, timeout, type) {
      this.clearFeatureList();

      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= this.searchLength) {
          this.emitSearch(text, localTimeout, type);
        }
      }
    },
    emitSearch(text, timeout, type) {
      clearTimeout(this.timer);
      this.featureBlur();
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);

        this.searchFeature({
          query: text,
          selected: helper.arrayPluck(this.computedData, "id"),
          lang: this.language,
          onSuccess: (result) => {
            helper.copy(result.data.items, this.filteredFeatureList);
            this.searchReady = true;
            this.featureVisible = true;
          },
        });
      }, timeout);
    },

    addFeature(feature, values) {
      const newFeature = {
        id: null,
        value: null,
        feature_id: feature.id,
        feature_name: feature.name,
        values: values,
      };

      this.computedData.push(newFeature);

      this.searchTerm = "";
      this.clearFeatureList();
    },
    getFeature(feature) {
      if (this.getRequest) {
        this.getFeatureById({
          lang: this.language,
          id: feature.id,
          onSuccess: (result) => {
            if (result && result.data && result.data.item) {
              this.addFeature(feature, result.data.item.values);
            }
          },
        });
      } else {
        this.addFeature(feature, []);
      }
    },
    featureBlur() {
      setTimeout(() => {
        this.featureVisible = false;
      }, 250);
    },
    onBlur() {
      setTimeout(() => {
        this.filteredValueVisible = false;
      }, 250);
    },
    clearSearchIndex() {
      this.valueSearchIndex = -1;
      this.onBlur();
    },
    clearFilteredValue() {
      this.filteredValueSearch = [];
    },
    onFeatureValueChange(feature, index, e) {
      let text = e.target.value;

      this.clearFilteredValue();
      this.clearSearchIndex();
      feature.value = text;

      if (text && text.length >= this.searchLength) {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          clearTimeout(this.timer);
          this.valueSearchIndex = index;

          this.searchFeatureValue({
            lang: this.language,
            feature,
            onSuccess: (result) => {
              if (this.valueType == "input") {
                if (
                  result.data &&
                  result.data.items &&
                  result.data.items.length
                ) {
                  helper.copy(result.data.items, this.filteredValueSearch);
                  this.filteredValueVisible = true;
                } else {
                  delete feature.id;
                }
              }
            },
          });
        }, this.searchDelay);
      }
    },
    removeFeature(id) {
      this.clearSearchIndex();
      helper.arrayRemove(this.computedData, "feature_id", id);
    },
    addFeatureValue(feature, value) {
      this.clearFilteredValue();
      feature.id = value.id;
      feature.value = value.name;
    },
    getSelectable(feature) {
      if (this.valueLimit > 0) {
        if (feature.value) {
          if (feature.value.length > this.valueLimit) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    ...mapState({
      feature: (state) => state.shared.feature,
    }),
    computedData() {
      return this.data;
    },
    checkFeatureLimit() {
      if (this.featureLimit > 0) {
        if (this.computedData.length >= this.featureLimit) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.clearFeatureList();
  },
};
</script>
<style lang="scss" scoped>
.result-count {
  position: absolute;
  right: 10px;
  z-index: 1060;
  top: 7px;
}
// .feature-card-container {
//   max-height: 300px;
//   overflow: auto;
// }
</style>
